import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import { CustomFieldIcon, Icon, PriorityIcon, SeverityIcon } from '@clubhouse/shapes-ds';
import { BidirectionalArrows } from '../components/Icons';
import { OpinionatedFieldType, StoryTypes } from '../types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const OpinionatedIcon = ({
  icon
}) => _jsx(Icon, {
  icon: icon,
  fill: "currentColor"
});
OpinionatedIcon.displayName = "OpinionatedIcon";
// TODO: Replace with the utility function in `utils/customField` when this is moved out of the shared package
const COMPATIBLE_ICON_IDENTIFIERS = ['One', 'Two', 'Three', 'Four', 'Five', 'Six', 'A', 'ArrowLeft', 'ArrowRight', 'Art', 'Automotive', 'B', 'Bolt', 'Book', 'Bookmark', 'Bookmark2', 'C', 'Camera', 'Check', 'Cloud', 'Cup', 'D', 'Dice', 'E', 'F', 'Flame', 'Flower', 'Folder', 'Graph', 'Hand', 'Heart', 'Lock', 'Mail', 'Media', 'Mountain', 'Person', 'Phone1', 'Phone', 'PieChart', 'Play', 'Shield', 'Star', 'Target', 'ThumbsDown', 'ThumbsUp', 'Travel', 'User', 'Watch'];
export const OPINIONATED_ICONS = {
  // deprecated (early prototype)
  [OpinionatedFieldType.SERVICE]: () => _jsx(OpinionatedIcon, {
    icon: "Settings"
  }),
  [OpinionatedFieldType.DISCIPLINE]: () => _jsx(OpinionatedIcon, {
    icon: "Skillset"
  }),
  [OpinionatedFieldType.FEATURE_AREA]: () => _jsx(OpinionatedIcon, {
    icon: "FeatureArea"
  }),
  // Fields MVP
  [OpinionatedFieldType.TECHNICAL_AREA]: () => _jsx(OpinionatedIcon, {
    icon: "Settings"
  }),
  [OpinionatedFieldType.SKILL_SET]: () => _jsx(OpinionatedIcon, {
    icon: "Skillset"
  }),
  [OpinionatedFieldType.PRODUCT_AREA]: () => _jsx(OpinionatedIcon, {
    icon: "FeatureArea"
  }),
  // post-MVP
  [OpinionatedFieldType.PRIORITY]: BidirectionalArrows,
  [OpinionatedFieldType.SEVERITY]: () => _jsx(OpinionatedIcon, {
    icon: "Warn"
  })
};
export const PRIORITY_ICONS = ['Highest', 'High', 'Medium', 'Low', 'Lowest'];
export const SEVERITY_ICONS = ['Severity0', 'Severity1', 'Severity2', 'Severity3', 'Severity4', 'Severity5', 'Severity6'];
const getOpinionatedFieldIcon = type => (type && OPINIONATED_ICONS[type]) ?? null;
export const getCustomFieldIcon = ({
  icon_set_identifier,
  canonical_name
}) => icon_set_identifier && COMPATIBLE_ICON_IDENTIFIERS.includes(icon_set_identifier) ? () => _jsx(CustomFieldIcon, {
  icon: icon_set_identifier,
  fill: "currentColor"
}) : getOpinionatedFieldIcon(canonical_name) ?? getDefaultCustomFieldIcon;
const getDefaultCustomFieldIcon = () => _jsx(CustomFieldIcon, {
  icon: "Folder",
  fill: "currentColor"
});
getDefaultCustomFieldIcon.displayName = "getDefaultCustomFieldIcon";
// Use this to help transition to object structure
// https://www.notion.so/useshortcut/Custom-fields-API-changes-4c5d80072ce649318a1db069f7da89cf#4a2b33e964924710ba0c483c291bcc21
export const generateEmptyStoryCustomFields = ({
  custom_fields
}) => custom_fields ?? [];
export const isSeverityOrPriority = type => type === OpinionatedFieldType.PRIORITY || type === OpinionatedFieldType.SEVERITY;
export const isSeverity = type => type === OpinionatedFieldType.SEVERITY;
export const isPriority = type => type === OpinionatedFieldType.PRIORITY;
export const getFieldValueIcon = (field, valueId) => {
  const fieldIcon = getOpinionatedFieldIcon(field.canonical_name);
  switch (field.canonical_name) {
    case OpinionatedFieldType.SEVERITY:
      {
        return valueId ? () => _jsx(SeverityIcon, {
          icon: SEVERITY_ICONS[field.values.findIndex(v => v.id === valueId)]
        }) : fieldIcon;
      }
    case OpinionatedFieldType.PRIORITY:
      {
        return valueId ? () => _jsx(PriorityIcon, {
          icon: PRIORITY_ICONS[field.values.findIndex(v => v.id === valueId)]
        }) : fieldIcon;
      }
    default:
      {
        return fieldIcon || getCustomFieldIcon(field);
      }
  }
};
export const filterFieldsByStoryType = (type, fields) => {
  if (type !== StoryTypes.BUG) {
    return fields.filter(field => field.canonical_name !== OpinionatedFieldType.SEVERITY);
  }
  return fields;
};