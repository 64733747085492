import { Icon } from '@clubhouse/shapes-ds';
import { COLORS } from '../../colors';
import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { DeprecatedIconAdapter } from './DeprecatedIconAdapter';

/**
 * @deprecated Use <Icon icon="Upload" /> from @useshortcut/shapes-ds
 */
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UploadCloud = ({
  width = ICON_DEFAULT_WIDTH
}) => _jsx(DeprecatedIconAdapter, {
  fill: COLORS.GRAY65,
  width: width,
  children: _jsx(Icon, {
    icon: "Upload"
  })
});
UploadCloud.displayName = "UploadCloud";