import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.reduce.js";
/**
 * Groups an array of items by a key.
 *
 * Warning: The following implementation uses a `Map`, so the order of the
 * grouped values is determined by the order they occur in the collection.
 */
export const groupBy = (list, getGroupKey) => {
  return list.reduce((map, item) => {
    const key = getGroupKey(item);
    if (!map.has(key)) {
      map.set(key, []);
    }
    map.get(key).push(item);
    return map;
  }, new Map());
};