import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import { useCallback, useState } from 'react';
export const useListState = (initialState = []) => {
  const [list, setList] = useState(initialState);
  const addItem = useCallback(item => {
    setList(prevList => [...prevList, item]);
  }, []);
  const removeItem = useCallback(item => {
    setList(prevList => prevList.filter(i => i !== item));
  }, []);
  const clearList = useCallback(() => {
    setList([]);
  }, []);
  return {
    list,
    addItem,
    removeItem,
    clearList
  };
};