import { ThirdPartyIcon } from '@clubhouse/shapes-ds';
import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { DeprecatedIconAdapter } from './DeprecatedIconAdapter';

/**
 * @deprecated Use <ThirdPartyIcon icon="Github" /> from @useshortcut/shapes-ds
 */
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const GithubLogo = ({
  width = ICON_DEFAULT_WIDTH
}) => _jsx(DeprecatedIconAdapter, {
  width: width,
  children: _jsx(ThirdPartyIcon, {
    icon: "Github"
  })
});
GithubLogo.displayName = "GithubLogo";