import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/web.structured-clone.js";
import { useMemo, useRef, useState } from 'react';
export const useMapState = initialData => {
  const {
    current: map
  } = useRef(initialData || new Map());
  const [, setState] = useState({});
  return useMemo(() => ({
    set(key, item) {
      map.set(key, item);
      setState({});
    },
    remove(key) {
      map.delete(key);
      setState({});
    },
    hasKey(key) {
      return map.has(key);
    },
    get(key) {
      return map.get(key);
    },
    clear() {
      map.clear();
      setState({});
    },
    forEach(fn) {
      map.forEach(fn);
    },
    entries() {
      return Array.from(map.entries());
    },
    values() {
      return Array.from(map.values());
    },
    keys() {
      return Array.from(map.keys());
    },
    get isEmpty() {
      return map.size === 0;
    },
    clone() {
      return structuredClone(map);
    }
  }), []);
};