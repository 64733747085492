import { Icon } from '@clubhouse/shapes-ds';
import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { DeprecatedIconAdapter } from './DeprecatedIconAdapter';

/**
 * @deprecated Use <Icon icon="Table" /> from @useshortcut/shapes-ds
 */
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const TableView = ({
  width = ICON_DEFAULT_WIDTH,
  fill
}) => {
  return _jsx(DeprecatedIconAdapter, {
    fill: fill,
    width: width,
    children: _jsx(Icon, {
      icon: "Table"
    })
  });
};
TableView.displayName = "TableView";