import { Icon } from '@clubhouse/shapes-ds';
import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { DeprecatedIconAdapter } from './DeprecatedIconAdapter';

/**
 * @deprecated Use <Icon icon="Story" /> from @useshortcut/shapes-ds
 */
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const Story = ({
  fill,
  width = ICON_DEFAULT_WIDTH
}) => {
  return _jsx(DeprecatedIconAdapter, {
    width: width,
    fill: fill,
    children: _jsx(Icon, {
      icon: "Story"
    })
  });
};
Story.displayName = "Story";