import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.reduce.js";
import memoize from 'lodash/memoize';
import { ACCENTED_CONVERSIONS } from '../../constants';
export const convertAccentedCharacters = memoize(str => {
  if (!str || !str.replace) {
    return str;
  }
  Object.keys(ACCENTED_CONVERSIONS).forEach(toStr => {
    const fromStr = ACCENTED_CONVERSIONS[toStr];
    str = str.replace(fromStr, toStr);
  });
  return str;
});
export const generateAbbreviation = (text, limit = Number.POSITIVE_INFINITY) => {
  let abbreviation = '';
  const converted = convertAccentedCharacters(text);
  if (!converted || !converted.replace) {
    return converted;
  }
  const words = converted.replace(/[^a-zA-Z0-9 ]/g, '').split(' ');
  words.forEach(word => {
    abbreviation += word[0] ? word[0] : '';
  });
  return abbreviation.slice(0, limit).toUpperCase();
};

// Ref: https://www.kernel.org/pub/software/scm/git/docs/git-check-ref-format.html
// Accented Regex: http://stackoverflow.com/a/11550799/440094
export const slugify = (str, options) => {
  const optionsWithDefault = Object.assign({
    delimiter: '-',
    limit: 40,
    toLowerCase: true
  }, options);
  const words = (str + '').replace(/[^A-Za-z0-9\u00C0-\u017F]/g, ' ').replace(/\s\s+/g, ' ').trim().split(' ');
  const output = words.reduce((output, word) => {
    if (output.length >= optionsWithDefault.limit) return output;
    return output + word + ' ';
  }, '').trim().replace(/ /g, optionsWithDefault.delimiter);
  return optionsWithDefault.toLowerCase ? output.toLowerCase() : output;
};
export const startsWithVowel = str => str.match(/^[aeiou]/i);