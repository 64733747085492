import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { useEffect, useState } from 'react';
export function useVisibilityObserver(ref, {
  enable = true,
  ...options
} = {}) {
  const [visible, setVisible] = useState(false);

  // biome-ignore lint/correctness/useExhaustiveDependencies: We don't include options because it is only used on initial render
  useEffect(() => {
    if (enable === false) return;
    if (ref.current) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          setVisible(entry.isIntersecting);
        });
      }, options);
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [ref, enable]);
  return visible || enable === false;
}