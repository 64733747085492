import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.every.js";
import { useEffect } from 'react';
import { isEventInBounds } from './useClickOutside';
export const useMouseOutside = (refs, callback, attachHandler = true) => {
  useEffect(() => {
    const handleMouseOut = e => {
      if (refs.every(ref => !isEventInBounds(e, ref))) {
        callback();
      }
    };
    if (attachHandler) {
      document.addEventListener('mouseout', handleMouseOut);
    } else {
      document.removeEventListener('mouseout', handleMouseOut);
    }
    return () => {
      document.removeEventListener('mouseout', handleMouseOut);
    };
  }, [attachHandler, callback, refs]);
};