import { getTokens } from '@clubhouse/shapes-ds';
import { COLORS } from '@clubhouse/shared/colors';
import { THEME_NAMES } from '@clubhouse/shared/utils/theme';
const lightTokens = getTokens('light');
const darkTokens = getTokens('dark');
export const tableTheme = {
  [THEME_NAMES.LIGHT]: {
    tableRowSelectedBackgroundColor: COLORS.BLUE97,
    tableRowActiveBorderTopColor: COLORS.BLUE64,
    tableRowHoverColor: lightTokens.color.semantic.background.canvas,
    tableHeaderColor: lightTokens.color.semantic.text.secondary,
    tableHeaderHoverColor: COLORS.GRAY27,
    tableHeaderActiveColor: lightTokens.color.semantic.interactive.main,
    tableHeaderIconColor: lightTokens.color.semantic.interactive.main,
    tableDraggingShadowColor: 'hsl(0, 0%, 70%, 0.4)',
    tableBorderColor: 'rgba(0, 0, 0, 0.07)',
    tableColumnHeaderHoverBGColor: lightTokens.color.semantic.background.tertiary
  },
  [THEME_NAMES.DARK]: {
    tableRowSelectedBackgroundColor: COLORS.BLUE22,
    tableRowActiveBorderTopColor: COLORS.BLUE64,
    tableRowHoverColor: darkTokens.color.semantic.interactive.hover,
    tableHeaderColor: COLORS.GRAY50,
    tableHeaderHoverColor: COLORS.GRAY70,
    tableHeaderActiveColor: darkTokens.color.semantic.interactive.main,
    tableHeaderIconColor: darkTokens.color.semantic.interactive.main,
    tableDraggingShadowColor: 'hsl(0, 0%, 0%, 0.4)',
    tableBorderColor: 'rgba(255, 255, 255, 0.07)',
    tableColumnHeaderHoverBGColor: darkTokens.color.semantic.background.tertiary
  }
};