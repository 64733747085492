import { useRef } from 'react';
import { insertIf } from '../utils/object';
import { useKeyboardListNavigation } from './useKeyboardListNavigation';
export function useRadioGroup({
  onSelect
}) {
  const radiogroupRef = useRef(null);
  const activeRef = useRef(null);
  const groupKeyboardProps = useKeyboardListNavigation({
    listRef: radiogroupRef,
    activeRef
  });
  return {
    getRadioGroupProps: ({
      label
    }) => ({
      ...groupKeyboardProps,
      ref: radiogroupRef,
      role: 'radiogroup',
      ...insertIf(!!label, {
        'aria-label': label
      })
    }),
    getRadioProps: ({
      checked,
      value,
      label,
      disabled
    }) => {
      const onFocus = () => {
        if (disabled) return;
        if (!checked) onSelect(value);
      };
      const onClick = e => {
        e.preventDefault();
        if (disabled) return;
        if (!checked) onSelect(value);
      };
      return {
        ref: checked ? activeRef : undefined,
        role: 'radio',
        tabIndex: checked ? 0 : -1,
        'aria-checked': checked,
        'aria-disabled': disabled ?? undefined,
        ...insertIf(!!label, {
          'aria-label': label
        }),
        onFocus,
        onClick
      };
    }
  };
}