import { Icon } from '@clubhouse/shapes-ds';
import { DeprecatedIconAdapter } from './DeprecatedIconAdapter';

/**
 * @deprecated Use <Icon icon="Warn" /> from @useshortcut/shapes-ds
 */
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const ExclamationTriangle = ({
  fill = '#cccb4a',
  width = '12'
}) => _jsx(DeprecatedIconAdapter, {
  width: width,
  fill: fill,
  children: _jsx(Icon, {
    icon: "Warn"
  })
});
ExclamationTriangle.displayName = "ExclamationTriangle";