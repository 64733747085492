function createNouns(obj) {
  return obj;
}
export const Nouns = createNouns({
  Category: {
    singular: 'Category',
    plural: 'Categories'
  },
  Comment: {
    singular: 'Comment',
    plural: 'Comments'
  },
  Doc: {
    singular: 'Doc',
    plural: 'Docs'
  },
  Epic: {
    singular: 'Epic',
    plural: 'Epics'
  },
  Estimate: {
    singular: 'Estimate',
    plural: 'Estimates'
  },
  Field: {
    singular: 'Field',
    plural: 'Fields'
  },
  Health: {
    singular: 'Health',
    plural: 'Healths'
  },
  Item: {
    singular: 'Item',
    plural: 'Items'
  },
  Iteration: {
    singular: 'Iteration',
    plural: 'Iterations'
  },
  KeyResult: {
    singular: 'Key Result',
    plural: 'Key Results'
  },
  Label: {
    singular: 'Label',
    plural: 'Labels'
  },
  Milestone: {
    singular: 'Milestone',
    plural: 'Milestones'
  },
  Objective: {
    singular: 'Objective',
    plural: 'Objectives'
  },
  Owner: {
    singular: 'Owner',
    plural: 'Owners'
  },
  Point: {
    singular: 'Point',
    plural: 'Points'
  },
  Priority: {
    singular: 'Priority',
    plural: 'Priorities'
  },
  ProductArea: {
    singular: 'Product Area',
    plural: 'Product Areas'
  },
  Project: {
    singular: 'Project',
    plural: 'Projects'
  },
  Reply: {
    singular: 'Reply',
    plural: 'Replies'
  },
  Requester: {
    singular: 'Requester',
    plural: 'Requesters'
  },
  Role: {
    singular: 'Role',
    plural: 'Roles'
  },
  Severity: {
    singular: 'Severity',
    plural: 'Severities'
  },
  SkillSet: {
    singular: 'Skill Set',
    plural: 'Skill Sets'
  },
  Space: {
    singular: 'Space',
    plural: 'Spaces'
  },
  State: {
    singular: 'State',
    plural: 'States'
  },
  Story: {
    singular: 'Story',
    plural: 'Stories'
  },
  StoryType: {
    singular: 'Story Type',
    plural: 'Story Types'
  },
  Subtask: {
    singular: 'Sub-task',
    plural: 'Sub-tasks'
  },
  Template: {
    singular: 'Story Template',
    plural: 'Story Templates'
  },
  Team: {
    singular: 'Team',
    plural: 'Teams'
  },
  TechnicalArea: {
    singular: 'Technical Area',
    plural: 'Technical Areas'
  },
  Type: {
    singular: 'Type',
    plural: 'Types'
  },
  Workflow: {
    singular: 'Workflow',
    plural: 'Workflows'
  },
  Workspace: {
    singular: 'Workspace',
    plural: 'Workspaces'
  }
});