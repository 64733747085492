import "core-js/modules/es.array.push.js";
import "core-js/modules/es.set.difference.v2.js";
import "core-js/modules/es.set.intersection.v2.js";
import "core-js/modules/es.set.is-disjoint-from.v2.js";
import "core-js/modules/es.set.is-subset-of.v2.js";
import "core-js/modules/es.set.is-superset-of.v2.js";
import "core-js/modules/es.set.symmetric-difference.v2.js";
import "core-js/modules/es.set.union.v2.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
/** Focusable elements */
export const ELEMENTS = ['a[href]', 'area[href]', 'summary', 'iframe', 'object', 'embed', 'audio[controls]', 'video[controls]', '[contenteditable]', ...['input:not([type="hidden"])', 'textarea', 'select', 'button'].map(selector => selector + ':not([disabled])')].map(selector => selector + ':not([hidden])');

/** Tabbable elements */
export const TABBABLE_SELECTOR = [...ELEMENTS.map(selector => selector + ':not([tabindex="-1"])'), '[tabindex="0"]'].join(',');

/** Includes standard tabbable elements + elements with aria-selected="true" & elements with data-focusable="true".
 *  Only used for quicksearch Tab key navigation when re-entering the search results tab headings
 *  or the search operator suggestions list from the input.
 */
const TABBABLE_SELECTOR_QUICK_SEARCH = [...TABBABLE_SELECTOR.split(','), '[aria-selected="true"]', '[data-focusable="true"]', '[data-tabindex="0"]'].join(',');

/** Focusable elements */
export const FOCUSABLE_SELECTOR = [...ELEMENTS, '[tabindex]:not([disabled]):not([hidden])'].join(',');
export const isFocusable = el => el ? el.matches(FOCUSABLE_SELECTOR) && isVisible(el) : false;
export const isVisible = el => !!el && (el.offsetWidth > 0 || el.offsetHeight > 0) || false;
export const getFocusableChildren = el => el ? Array.from(el.querySelectorAll(FOCUSABLE_SELECTOR)).filter(isVisible) : [];
export const getTabbableChildren = el => el ? Array.from(el.querySelectorAll(TABBABLE_SELECTOR)).filter(isVisible) : [];

// Only used for quicksearch tab key navigation when re-entering the search results tab headings or the search operator suggestions list from the input.
export const getTabbableChildrenQuickSearch = el => el ? Array.from(el.querySelectorAll(TABBABLE_SELECTOR_QUICK_SEARCH)).filter(isVisible) : [];
export const focusWithoutScroll = element => {
  if (!element) return;
  if (supportsPreventScroll()) {
    element.focus({
      preventScroll: true
    });
  } else {
    const state = getScrollState(element);
    element.focus();
    state.restore();
  }
};
export const hasVerticalScrollbar = el => el ? el.offsetHeight < el.scrollHeight : false;
export const hasHorizontalScrollbar = el => el ? el.offsetWidth < el.scrollWidth : false;
const scrollValues = new Set(['auto', 'scroll']);
export const canOverflowWithScroll = (el, dirToCheck) => {
  if (!el) return false;
  const styles = window.getComputedStyle(el);
  if (scrollValues.has(styles.overflow)) return true;
  if (dirToCheck !== 'horizontal' && scrollValues.has(styles.overflowY)) return true;
  if (dirToCheck !== 'vertical' && scrollValues.has(styles.overflowX)) return true;
  return false;
};
export const isScrollable = (el, dirToCheck, method) => {
  if (dirToCheck !== 'horizontal') {
    if (method === 'scrollable' && hasVerticalScrollbar(el)) return true;else if (method === 'css' && canOverflowWithScroll(el, dirToCheck)) return true;
  }
  if (dirToCheck !== 'vertical') {
    if (method === 'scrollable' && hasHorizontalScrollbar(el)) return true;else if (method === 'css' && canOverflowWithScroll(el, dirToCheck)) return true;
  }
  return false;
};
export const getScrollableParents = (element, {
  dirToCheck = 'both',
  method = 'scrollable'
} = {}) => {
  const scrollableElements = [];
  if (!element) return scrollableElements;
  let el = element.parentNode;
  const root = document.scrollingElement || document.documentElement;
  const create = el => ({
    el,
    ...(dirToCheck !== 'horizontal' && {
      top: el.scrollTop
    }),
    ...(dirToCheck !== 'vertical' && {
      left: el.scrollLeft
    })
  });
  while (el instanceof HTMLElement && el !== root) {
    if (isScrollable(el, dirToCheck, method)) {
      scrollableElements.push(create(el));
    }
    el = el.parentNode;
  }
  if (root instanceof HTMLElement) {
    scrollableElements.push(create(root));
  }
  return scrollableElements;
};
export const getScrollState = element => {
  const scrollableElements = getScrollableParents(element, {
    dirToCheck: 'both',
    method: 'scrollable'
  });
  return {
    restore: () => {
      scrollableElements.forEach(({
        el,
        left,
        top
      }) => {
        if (typeof top === 'number') el.scrollTop = top;
        if (typeof left === 'number') el.scrollLeft = left;
      });
    }
  };
};

// preventScroll is not supported in Safari (iOS and MacOs)
const supportsPreventScroll = (() => {
  let cache = null;
  return () => {
    if (cache === null) {
      cache = false;
      try {
        const temp = document.createElement('div');
        temp.focus({
          get preventScroll() {
            cache = true;
            return true;
          }
        });
      } catch {}
    }
    return !!cache;
  };
})();