import { getTokens } from '@clubhouse/shapes-ds';
import { COLORS } from '@clubhouse/shared/colors';
import { THEME_NAMES } from '@clubhouse/shared/utils/theme';
const lightTokens = getTokens('light');
const darkTokens = getTokens('dark');
export const LOCAL_THEME = {
  [THEME_NAMES.LIGHT]: {
    datepickerMonthHeaderColor: lightTokens.color.semantic.text.main,
    datepickerBackgroundColor: COLORS.WHITE,
    datepickerWeekdayBackground: lightTokens.color.raw.white,
    datepickerWeekendBackground: lightTokens.color.raw.white,
    datepickerSelectedFontColor: COLORS.WHITE,
    datepickerWeekdayFontColor: lightTokens.color.semantic.text.main,
    datepickerWeekendFontColor: lightTokens.color.semantic.text.secondary,
    datepickerDayFontColor: COLORS.GRAY50,
    datepickerNavigationTintColor: lightTokens.color.semantic.icon.main,
    datepickerNavigationTintHoverColor: lightTokens.color.semantic.icon.hover,
    datepickerNavigationBackgroundColor: COLORS.GRAY98,
    datepickerShadowColor: COLORS.BLACK,
    datepickerHoverColor: lightTokens.color.raw.blue70,
    datepickerFontHoverColor: lightTokens.color.semantic.text.highlight,
    datepickerBorderHoverColor: lightTokens.color.raw.blue80,
    datepickerEmptyDayBorderColor: 'transparent'
  },
  [THEME_NAMES.DARK]: {
    datepickerMonthHeaderColor: darkTokens.color.semantic.text.main,
    datepickerBackgroundColor: '#3C3C3C',
    datepickerWeekdayBackground: darkTokens.color.raw.slate40,
    datepickerWeekendBackground: darkTokens.color.raw.slate50,
    datepickerSelectedFontColor: COLORS.WHITE,
    datepickerWeekdayFontColor: COLORS.WHITE,
    datepickerWeekendFontColor: darkTokens.color.raw.slate20,
    datepickerDayFontColor: COLORS.GRAY70,
    datepickerNavigationTintColor: darkTokens.color.semantic.icon.main,
    datepickerNavigationTintHoverColor: darkTokens.color.semantic.icon.hover,
    datepickerNavigationBackgroundColor: '#3C3C3C',
    datepickerShadowColor: COLORS.GRAY93,
    datepickerHoverColor: darkTokens.color.raw.slate70,
    datepickerFontHoverColor: COLORS.WHITE,
    datepickerBorderHoverColor: lightTokens.color.raw.blue80,
    datepickerEmptyDayBorderColor: 'transparent'
  }
};