import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.reduce.js";
import { PALETTE_TINT, getTint } from '@clubhouse/shared/color';
export const DEFAULT_TEAM_TINT = 'light-gray';
export const colorKeyMap = {
  midnightBlue: 'midnight-blue',
  skyBlue: 'sky-blue',
  yellowGreen: 'yellow-green'
};
export const getTeamColor = team => {
  return getTint(getTeamColorKey(team));
};
export const getTeamColorKey = team => {
  if (team) {
    if ('color' in team && typeof team.color === 'string') return team.color;
    if ('color_key' in team && typeof team.color_key === 'string') return team.color_key;
    if ('colorKey' in team && typeof team.colorKey === 'string') {
      return colorKeyMap[team.colorKey] || team.colorKey;
    }

    // Not all teams will have a color assigned, and we want a stable random color. So we just use the
    // ID as a "random" number and use it to index the color palette
    const id = String(team.publicId || team.id);
    const derivedIndex = id.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
    const color = PALETTE_TINT[derivedIndex % PALETTE_TINT.length];
    return color;
  }
  return DEFAULT_TEAM_TINT;
};