import { Icon } from '@clubhouse/shapes-ds';
import { DeprecatedIconAdapter } from './DeprecatedIconAdapter';

/**
 * @deprecated Use <Icon icon="Export" /> from @useshortcut/shapes-ds
 */
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const ExportImage = ({
  fill,
  width = '12px'
}) => {
  return _jsx(DeprecatedIconAdapter, {
    width: width,
    fill: fill,
    children: _jsx(Icon, {
      icon: "Export"
    })
  });
};
ExportImage.displayName = "ExportImage";