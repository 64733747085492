import { Emojify } from '@clubhouse/shared/components/Emojify';
import { Text } from '@clubhouse/shared/components/Typography/Text';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const TableText = ({
  text,
  textAlign = 'center'
}) => {
  return _jsx(Text, {
    textAlign: textAlign,
    size: Text.SIZE.SMALL,
    lineClamp: 2,
    children: _jsx(Emojify, {
      children: text || '-'
    })
  });
};
TableText.displayName = "TableText";