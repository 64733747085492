import "core-js/modules/es.set.difference.v2.js";
import "core-js/modules/es.set.intersection.v2.js";
import "core-js/modules/es.set.is-disjoint-from.v2.js";
import "core-js/modules/es.set.is-subset-of.v2.js";
import "core-js/modules/es.set.is-superset-of.v2.js";
import "core-js/modules/es.set.symmetric-difference.v2.js";
import "core-js/modules/es.set.union.v2.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.reduce.js";
import { useState } from 'react';
export const useOptions = ({
  initialOptions
}) => {
  const [options, setOptions] = useState(initialOptions);
  return {
    options,
    setOptions
  };
};
const update = ({
  options,
  updater
}) => {
  return options.reduce((acc, option = {}) => {
    return [...acc, {
      ...option,
      ...updater(option)
    }];
  }, []);
};
export const getId = option => option.id;
export const getIsSelected = option => option.isSelected;
export const getValue = option => option.value;
function createOption({
  item,
  getItemId,
  selectedItemIds
}) {
  const id = getItemId(item);
  return {
    value: item,
    id,
    isSelected: selectedItemIds.has(id)
  };
}
const defaultGetItemId = item => item ? item.id ? item.id : item.value : undefined;
export function createOptions({
  items,
  selectedItemIds = [],
  getItemId = defaultGetItemId
}) {
  const set = new Set(selectedItemIds);
  return items.map(item => createOption({
    item,
    getItemId,
    selectedItemIds: set
  }));
}
export function recreateOptions({
  items,
  previousOptions
}) {
  return createOptions({
    items,
    selectedItemIds: previousOptions.filter(getIsSelected).map(getId)
  });
}
export const toggleOptions = ({
  options,
  selectedOptionIds
}) => {
  selectedOptionIds = new Set(selectedOptionIds);
  return update({
    options,
    updater: option => {
      const isSelected = getIsSelected(option);
      return {
        isSelected: selectedOptionIds.has(getId(option)) ? !isSelected : isSelected
      };
    }
  });
};
export const setIsSelectedForIds = ({
  options,
  selectedOptionIds
}) => {
  selectedOptionIds = new Set(selectedOptionIds);
  return update({
    options,
    updater: option => {
      return {
        isSelected: selectedOptionIds.has(getId(option))
      };
    }
  });
};