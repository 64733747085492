import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { getScrollableParents } from '../focus';
const isValidHTMLElement = el => !!el;
export const scrollToEl = (el, {
  offset = 0
} = {}) => {
  if (!isValidHTMLElement(el)) return;
  const [{
    el: scrollParent
  }] = getScrollableParents(el, {
    dirToCheck: 'vertical',
    method: 'css'
  });
  scrollParent.scrollTo({
    top: el.getBoundingClientRect().top - scrollParent.offsetTop - offset
  });
};
export const getTextWidth = (text, font = 'normal 16px Open Sans,Helvetica,Arial,sans-serif') => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) return 0;
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
};
export const freezeDocScroll = () => {
  document.documentElement.style.overflow = 'hidden';
};
export const unfreezeDocScroll = () => {
  document.documentElement.style.removeProperty('overflow');
};
export const toggleDocScroll = freeze => {
  if (freeze) freezeDocScroll();else unfreezeDocScroll();
};
export const closestAncestor = (el, selector) => {
  if (!el || !el.parentElement) return null;
  if (el.parentElement === document.body) return null;
  if (el.parentElement.matches?.(selector)) return el.parentElement;
  return closestAncestor(el.parentElement, selector);
};

// https://developer.mozilla.org/en-US/docs/Web/API/Event/cancelable#example
export const cancelEvent = event => {
  if (typeof event.cancelable !== 'boolean' || event.cancelable) {
    event.preventDefault();
    event.stopPropagation();
  } else {
    console.warn("The following event couldn't be canceled:");
    // eslint-disable-next-line no-console
    console.dir(event);
  }
};
export const subscribeToEvent = (target, type, listener) => {
  target.addEventListener(type, listener);
  return () => target.removeEventListener(type, listener);
};
export const createNode = ({
  type = 'div',
  id
}) => {
  const node = document.createElement(type);
  node.setAttribute('id', id);
  return node;
};
export const loadScript = (src, attributes, options) => {
  attributes = attributes || {};
  if (attributes.id) {
    const existing = document.getElementById(attributes.id);
    if (existing && options?.replace !== false) existing.remove();else if (existing) return Promise.resolve();
  }
  const element = document.createElement('script');
  Object.entries(attributes).forEach(([key, value]) => {
    element[key] = value;
  });
  element.src = src;
  return new Promise((resolve, reject) => {
    element.onload = resolve;
    element.onerror = () => {
      reject(new Error(`Failed to load script "${src}".`));
    };
    document.head.appendChild(element);
  });
};