import { Icon } from '@clubhouse/shapes-ds';
import { COLORS } from '../../colors';
import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { DeprecatedIconAdapter } from './DeprecatedIconAdapter';

/**
 * @deprecated Use <Icon icon="NewTab" /> from @useshortcut/shapes-ds
 */
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const Link = ({
  width = ICON_DEFAULT_WIDTH,
  fill = COLORS.GRAY50
}) => {
  return _jsx(DeprecatedIconAdapter, {
    width: width,
    fill: fill,
    children: _jsx(Icon, {
      icon: "NewTab"
    })
  });
};
Link.displayName = "Link";