import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import { FEATURE_TOGGLES, useVariation } from '@clubhouse/feature-toggles';
import { ColumnHeader } from './internalComponents/ColumnHeader';
import { ColumnHeaderWrapper } from './internalComponents/ColumnHeaderWrapper';
import { HeaderRow } from './internalComponents/HeaderRow';
import { getColumnSortProps } from './utils/getColumnSortProps';
import { getSortName } from './utils/getSortName';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function TableHeaderRow({
  collection,
  columns,
  getGridCellNavigationProps,
  allItems,
  sortColumn,
  sortDirection = 'ascending',
  onSort
}) {
  const {
    value: uiDensityEnabled
  } = useVariation(FEATURE_TOGGLES.UI_DENSITY);
  return _jsx(HeaderRow, {
    role: "row",
    children: columns.map(({
      name,
      displayName,
      width,
      minWidth,
      maxWidth,
      HeaderComponent,
      headerProps,
      sort,
      frozen
    }) => {
      const isSortable = !!sort;
      const sortName = getSortName({
        name,
        sort
      });
      return _jsx(ColumnHeaderWrapper, {
        centered: headerProps?.centered,
        width: width,
        minWidth: minWidth,
        maxWidth: maxWidth,
        isSortable: isSortable,
        frozen: frozen,
        ...getColumnSortProps({
          isSortable,
          displayName,
          name: sortName,
          sortColumn,
          sortDirection,
          onSort
        }),
        ...getGridCellNavigationProps({
          header: 'column'
        }),
        uiDensityEnabled: uiDensityEnabled,
        fullWidth: headerProps?.fullWidth,
        children: _jsx(ColumnHeader, {
          centered: headerProps?.centered,
          displayName: displayName,
          HeaderComponent: HeaderComponent,
          isSorted: sortColumn === sortName,
          leftPosition: headerProps?.leftPosition,
          collection: collection,
          allItems: allItems,
          name: name,
          sortDirection: sortDirection
        })
      }, name);
    })
  });
}
TableHeaderRow.displayName = "TableHeaderRow";