import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.reduce.js";
export const intersperse = ({
  items,
  separator
}) => {
  return items.reduce((acc, item, index) => {
    if (index === items.length - 1) {
      return [...acc, item];
    }
    return [...acc, item, separator({
      index: acc.length + 1,
      isLast: index === items.length - 2
    })];
  }, []);
};