import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { init as initEmojiMart } from 'emoji-mart';
export let emojiData;
export let supportedEmojis = new Map();
export const lazyLoadEmojiMart = async () => {
  if (emojiData) return;
  const module = await import(/* webpackChunkName: "emojis" */'@emoji-mart/data');
  if (emojiData) return;
  emojiData = module.default;
  await initEmojiMart({
    data: emojiData
  });
  Object.values(emojiData.emojis).forEach(e => {
    e.skins.forEach(s => {
      if (s.shortcodes) supportedEmojis.set(s.shortcodes, s.shortcodes);
    });
    if (e.aliases) {
      const shortcode = e.skins[0]?.shortcodes;
      if (shortcode) {
        e.aliases.forEach(a => {
          supportedEmojis.set(`:${a}:`, shortcode);
        });
      }
    }
  });
};
export const getSupportedEmoji = code => {
  if (!emojiData) return code;
  if (!code.startsWith(':')) code = ':' + code;
  if (!code.endsWith(':')) code = code + ':';
  return supportedEmojis.get(code);
};
export const getEmojiCodeFromUnicode = unicode => {
  for (const emoji of Object.values(emojiData.emojis)) {
    const match = emoji.skins.find(skin => skin.unified === unicode);
    if (match) return match.shortcodes;
  }
  return null;
};